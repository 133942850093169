import { operate } from '../util/lift';
import { noop } from '../util/noop';
import { createOperatorSubscriber } from './OperatorSubscriber';
import { innerFrom } from '../observable/innerFrom';
export function bufferWhen(closingSelector) {
  return operate((source, subscriber) => {
    let buffer = null;
    let closingSubscriber = null;
    const openBuffer = () => {
      closingSubscriber === null || closingSubscriber === void 0 ? void 0 : closingSubscriber.unsubscribe();
      const b = buffer;
      buffer = [];
      b && subscriber.next(b);
      innerFrom(closingSelector()).subscribe(closingSubscriber = createOperatorSubscriber(subscriber, openBuffer, noop));
    };
    openBuffer();
    source.subscribe(createOperatorSubscriber(subscriber, value => buffer === null || buffer === void 0 ? void 0 : buffer.push(value), () => {
      buffer && subscriber.next(buffer);
      subscriber.complete();
    }, undefined, () => buffer = closingSubscriber = null));
  });
}
